import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
});

async function getCommerceProfile(commerceId, menuUUID) {
  const response = await instance.get(
    `GetShopProfile?shopCode=${commerceId}&menuUUID=${menuUUID}`
  );
  const profile = response?.data?.profile;

  return profile;
}
async function sendReview(payload) {
  const reviewEndpoint = axios.create({
    baseURL: process.env.VUE_APP_REVIEW_API,
  });

  return await reviewEndpoint.post("V1/review/createReview", payload);
}

async function getPaymentCheckoutUrlOrder({
  table,
  shopName,
  shopCode,
  total,
  uuid,
  isPartial,
}) {
  const baseUrl =
    process.env.VUE_APP_MP_PAYMENT_BASE_URL || "http://localhost:8080";

  const payload = {
    table,
    shopName,
    shopCode,
    successUrl: `${baseUrl}/menu/order/details/payment/${
      isPartial ? "success-partial?uuid=" + uuid + "&" : "success?"
    }table=${table}&commerceId=${shopCode}`,
    failureUrl: `${baseUrl}/menu/order/details/payment/failure?${
      uuid ? "uuid=" + uuid : ""
    }&table=${table}&commerceId=${shopCode}`,
    total,
    method: "MP", //Este va fijo en MP por ahora.
    externalReference: uuid,
  };
  return await instance.post("borders", payload);
}

async function getPaymentCheckoutUrlDelivery({
  table,
  shopName,
  shopCode,
  total,
  userEmail,
  paymentMethod,
  payloadData,
  externalReference,
  isMobile,
}) {
  const baseUrl =
    process.env.VUE_APP_MP_PAYMENT_BASE_URL || "http://localhost:8080";

  let successUrl = `${baseUrl}/menu/weborders/payment/success?table=####&commerceId=${shopCode}`;
  if (paymentMethod === "Modo") {
    isMobile = true;
    successUrl = `${baseUrl}/menu/weborders/payment/modo/success?table=####&commerceId=${shopCode}`;
  }
  if (paymentMethod === "Nave") {
    isMobile = false;
    successUrl = `${baseUrl}/menu/weborders/payment/modo/success?table=####&commerceId=${shopCode}`;
  }

  const payload = {
    table,
    shopName,
    shopCode,
    userEmail,
    successUrl: successUrl,
    failureUrl: `${baseUrl}/menu/weborders/payment/failure?table=####&commerceId=${shopCode}`,
    total,
    payloadData,
    method: paymentMethod,
    externalReference,
    isMobile,
  };
  return await instance.post("borders", payload);
}
async function createPaymentPointPlus(payload) {
  return await instance.post("borders", payload);
}

function getIfIsValidPayment(payload) {
  return instance.get(
    `/BOrders/IsPaymentOk?ShopCode=${payload.shopCode}&ExternalReference=${payload.externalReference}`
  );
}

function getIfIsValidPaymentPointPlus(externalReference) {
  return instance.get(`/BOrders/IsPaymentOkPointPlus?Id=${externalReference}`);
}

// async function submitDeliveryOrder(payload) {
//   return await instance.post("/BOrders/ConfirmOrder", payload);
// }

// async function updateFailOrder(payload) {
//   return await instance.post("/BOrders/FailOrder", payload);
// }

async function getOrderStatus(payload) {
  let url = `BOrders/OrderStatus?shopCode=${payload.shopCode}&code=${payload.code}`;

  if (payload.pup) url = `${url}&pup=${encodeURIComponent(payload.pup)}`;
  return await instance.get(url);
}

export {
  getCommerceProfile,
  getPaymentCheckoutUrlOrder,
  getPaymentCheckoutUrlDelivery,
  createPaymentPointPlus,
  // submitDeliveryOrder,
  getIfIsValidPayment,
  getIfIsValidPaymentPointPlus,
  sendReview,
  // updateFailOrder,
  getOrderStatus,
};
